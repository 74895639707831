
$font: 'proxima-nova', sans-serif;
$fancy: 'lust', serif;

/* colours */
/* primary - in stylesheet order */
$one: #d7a59b; /* pink */
$two: #e0b444; /* yellow */
$three: #848539; /* khaki */
$four: #58a3a2; /* blue */
$five: #183642; /* navy */

/* mute */
$txt: #666;
$lightgrey: #b2b2b2;
$tint: #f1f1f1;

/* shared functions */
@function lighten($colour){
    @return mix(white, $colour, 15%);
}
@mixin dot(){
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $lightgrey;
    margin: 10px;
    transition: background-color $link_trans;
    &.active {
        background-color: $txt;
        &:hover {
            background-color: $txt;
            cursor: default;
        }
    }
}

$darken_bg: rgba(0,0,0, 0.3);

@mixin darken_before(){
    &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $darken_bg;
        z-index: 0;
    }
}
@mixin drop_shadow($blur: 4px){
    box-shadow: 0 0 $blur 2px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0 0 $blur 2px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0 0 $blur 2px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
}

$link_trans: 0.3s ease;

@mixin button(){
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    font-weight: normal;
    text-transform: capitalize;
    text-decoration: none;
    padding: 10px 15px;
    max-width: 100%;
    color: $four;
    background-color: transparent;
    border: 2px solid $four;
    box-sizing: border-box;
    transition: color $link_trans, background-color $link_trans;
    z-index: 1;
    font-weight:bold;
    &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-image: url('../assets/button-bg.jpg');
        background-size: cover;
        background-position: center;
        background-color: rgba(0,0,0,0.15);
        opacity: 0;
        z-index: -1;
        transition: opacity $link_trans, background-color $link_trans;
    }
    &:hover {
        color: #FFF !important;
        &:before {
            opacity: 1;
            background-blend-mode: multiply;
        }
    }
}


