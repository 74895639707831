@import 'shared';
@import 'store';

html {
    color: #222;
    font-size: 1em;
    line-height: 1.2;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $four; /* blue */
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
input,
select,
textarea,
button {
    border-radius:0; /* reset safari round corners */
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */

body {
    padding:0;
    margin:0;
    font-size: 18px;
    line-height: 1.2;
}

.contentwrap {
    max-width:1200px;
    margin: 0 auto;
    position:relative;
    padding: 30px;
}
header .contentwrap,
footer .contentwrap {
    padding: 40px 30px;
}

img {
    border:none;
}
a.logo {
    display: inline-block;
    width: 250px;
    height:auto;
    color: inherit;
    max-width: 100%;
    h1 {
        font-size: 1.2em;
        color: inherit;
        margin: 0px;
    }
}

/** menu generic **/
nav {
    ul {
        list-style:none;
        padding: 0;
        margin: 0;
    }
    li {
        display: block;
        padding: 0px; /* undo editor style */
        box-sizing: border-box;
        a {
            display: block;
            padding: 5px 0px;
            color: inherit;
            text-decoration: none;
            transition: color link_trans;
            &:hover {
                color: $five; /* navy */
            }
        }
        ul {
            display: none;
        }
    }
    h3 { /* in main menu */
        font-size: 1.2em;
        font-weight: normal;
        a {
            display: block;
            color: inherit; /* navy by default */
            text-decoration: none;
            transition: color $link_trans;
            &:hover {
                color: #FFF;
            }
        }
    }
    &.buttons-menu {
        > ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            align-content: center;
        }
        li {
            display: inline-block;
            margin: 5px;
            a {
                @include button;
            }
        }
    }
}

header a,
footer a,
.site-contact a {
    text-decoration: none;
    color: inherit;
}
/* contact block */
.site-contact {
    .item,
    .row {
        display: block;
    }
}

/** form generic **/
form {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    p {
        position: relative;
        margin: 5px 0;
        &.css-float-wrap {
            margin-top: 0.9em; /* space for label */
        }
    }
    &.search {
        p {
            padding-right: 40px; /* for button */
            input {
                border-right: 0px;
            }
        }
        label {
            color: inherit;
            font-weight: normal;
        }
        button {
            position: absolute;
            top: 0px;
            right: 0px;
            padding: 0px;
            width: 40px;
            height: 40px;
            background-color: $four; /* blue */
            background-image: url('../assets/search-icon.svg');
            background-repeat: no-repeat;
            background-size: 50%;
            background-position: center;
            border: 1px solid #FFF;
            transition: background-color $link_trans;
            cursor: pointer;
            &:before {
                display: none;
            }
            &:hover {
                background-color: $five; /* navy */
            }
        }
    }
}

label.float,
input,
select {
    height: 40px;
    line-height: 40px;
}
input,
textarea,
select {
    text-align: left;
    padding-left: 20px;
    color: $five; /* navy */
    font-family: $font;
    font-size: 16px;
    width: 100%;
    border: 1px solid $four; /* blue */
    background-color: transparent;
    box-sizing: border-box;
}
textarea {
    min-height: 80px;
}
select {
    color: $four; /* same as labels */
}
option {
    &:checked {
        background-color: lighten($four); /* blue 15% */
    }
}
label {
    color: $four;
    text-transform: capitalize;
    font-weight: bold;
    &.float {
        position: absolute;
        left:0;
        padding-left: 20px;
        max-width: 100%;
        box-sizing: border-box;
        transition: all 1s ease;
        z-index: 1;
    }
    &.error {
        display: block;
        font-weight: normal;
        font-size: 12px;
        line-height: 1;
    }
}
.js-float-wrap.focused,
.js-float-wrap.populated,
.css-float-wrap input:focus +,
.css-float-wrap input:valid + {
    label.float {
        left: 5px;
        padding-left: 0;
        line-height: 1;
        height: auto;
        font-size: 0.75em;
        top: -1em;
    }
}
form button {
    @include button;
}

/** site links **/
.site-links {
    padding-top: 20px;
    margin-left: -5px; /* align icons with wrapper */
}
.site-link {
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    transition: background-color $link_trans;
    margin: 5px;
}
.site-link:hover {
    background-color: $one; /* pink */
}
.slide-menu {
    .site-link:hover {
        background-color: $five; /* navy */
    }
}

/** common **/
.bg-one {
    color: #FFF;
    background-color: $one; /* pink */
    h3, h4 {
        color: #FFF;
    }
    .button {
        color: #FFF;
        border-color: #FFF;
    }
}
.tint {
    background-color: $tint;
}
.flex { /* lists of things */
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    justify-content: space-evenly;
    justify-content: space-between;
    align-content: stretch; /* height */
    box-sizing: border-box;
}
@mixin responsive_square(){
    position: relative;
    background-position: center;
    box-sizing: border-box;
    &:before { /* responsive square, based on width */
        content: "";
        display: block;
        width: 100%;
        padding-top: 100%;
    }
}
.square,
.circle {
    @include responsive_square;
    background-color: $lightgrey;
    background-size: cover;
}
.circle {
    border-radius: 50%;
}
.scrollwrap {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    overflow-x: scroll;
}
.scroller {
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    &.slide {
        transition: transform 1s linear;
    }
    > div {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
    }
}
.scroll-nav {
    display: none;
    text-align: left;
    padding: 20px 0px;
    margin-left: -10px; /* align dot with wrapper */
    .nav-item {
        @include dot;
    }
}
.js {
    .scrollwrap {
        overflow: hidden;
    }
}


/*============ header ============*/
header {
    color: #ffffff;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 999; /* below slide */
    .contentwrap {
        padding-right: 65px; /* for menu-icon */
        max-width: 100%;
        pointer-events: none;
        > * {
            pointer-events: auto;
        }
    }
}

#mobile-menu,
.mobile-menu {
    position: absolute;
    top: 40px;
    right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 100;
    > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #FFF;
        margin: 6px 0;
        transition: all 0.5s ease;
        /* active state below slide-menu */
    }
}

/***** Slide menu ****/
.site-content-menu-slide {
    overflow-x:hidden;
    position:relative;
    right:0;
}
/* transition for menu slide */
.slide-menu,
.site-content-menu-slide,
#mobile-menu,
.mobile-menu {
	-webkit-transition: all $link_trans;
	-moz-transition: all $link_trans;
	transition: all $link_trans;
}

.slide-menu {
    position: fixed;
	top: 0;
	width: 640px;
	right: -640px;
	height: 100%;
    display: block;
    color: #FFF;
    background: $four;
	box-sizing: border-box;
    overflow-y: auto;
	z-index: 1000; /* above it all */
	.wrap {
	    display: flex; /* so we can change the search order */
	    flex-direction: column;
	    width: 100%;
	    padding: 40px;
	    padding-left: 20%;
	    padding-top: 100px; /* for menu icon */
	    box-sizing: border-box;
	}
	.menu-title {
	    display: none;
        padding: 1em;
        color: $five; /* navy */
        font-weight: normal;
        margin:0;
    }
    ul {
        padding:0;
        margin:0;
        list-style:none;
    }
    li {
        position: relative;
        display: block;
        > ul {
            display:none;
        }
        &.expand-active > ul {
            display:block;
        }
        &.has-sub > a {
            position: relative;
            border-right: 1px solid #FFF;
            margin-right: 50px; /* space for drop */
        }
        a {
            color: inherit;
            font-size: 19px;
            transition: color $link_trans;
            &:hover {
                color: $five;
            }
        }
    }
    .expand {
        position: absolute;
        top: 0;
        right: 0;
        height: 54px;
        width: 50px;
        cursor: pointer;
        i {
            content:"";
            display: block;
            height: 100%;
            width: 100%;
            background: url('../assets/menu-drop.svg') no-repeat center center;
            background-size: 30%;
            -webkit-transition: -webkit-transform 500ms ease;
            transition: -webkit-transform 500ms ease;
            transition: transform 500ms ease;
            transition: transform 500ms ease, -webkit-transform 500ms ease;
        }
    }
    .expand-active > .expand i {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    nav {
        padding: 30px 0px;
    }
    form.search {
        order: 1; /* push to end */
        width: 250px;
        input {
            border-color: #FFF;
        }
    }
}
.slide-menu-active {
    .slide-menu {
        right: 0px;
    }
    #mobile-menu,
    .mobile-menu {
        span {
            background-color: $five;
        }
        span.one {
            -webkit-transform: rotate(-45deg) translate(-6px, 6px);
            transform: rotate(-45deg) translate(-6px, 6px);
        }
        span.three {
             -webkit-transform: rotate(45deg) translate(-6px, -6px) ;
            transform: rotate(45deg) translate(-6px, -6px) ;
        }
        span.two {
            opacity: 0;
        }
    }
}

/*============ footer ============*/
#subscribe {
    background-color: $three;
    .subscribe-top {
        position: relative;
        padding: 30px 0px;
        border-bottom: 3px solid #FFF;
        &:before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            display: block;
            width: 100%;
            height: 100%;
            background-image: url('../assets/pattern.jpg');
            background-color: #cbcbcb;
            background-blend-mode: multiply;
        }
        .contentwrap {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            text-align: center; /*fallback*/
        }
        h2 {
            line-height: 1;
            margin: 0px 20px;
        }
        .button {
            margin: 10px 0px;
        }
    }
}
#s-expand {
    cursor: pointer;
}
#subscribe-expand {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s ease;
    form {
        width: 100%;
        color: #FFF;
        label {
            color: inherit;
        }
        .recaptcha-notice {
            display: none;
        }
    }
    .contentwrap {
        padding-top: 20px;
        padding-bottom: 40px;
    }
    &.active {
        max-height: 1000px; /* adjusted in js */
    }
}
/* activecampaign overrides */
/* generic */
._form_1_,
#_form_3_,
#_form_5_ {
    background: transparent !important;
    padding: 0px;
    width: 100%;
    border: 0px !important;
    box-sizing: border-box;
    max-width: 100%;
    &._inline-form {
        ._form-content,
        input,
        ._submit {
            font-size: 16px;
            font-family: $font;
            color: inherit;
            line-height: 1;
            border-radius: 0px !important;
        }
        ._submit {
            @include button;
            font-weight: bold;
        }
    }
    input[type="text"] {
        padding: 8px;
        background-color: transparent;
    }
    ._form-label,
    ._form_element ._form-label {
        font-size: 16px;
        font-weight: normal;
    }
    ._form_element ._form-label {
        font-weight: bold;
    }
    ._submit {
        line-height: 1.2;
        color: $four !important;
        background-color: transparent !important;
        border: 1px solid $four !important;
        border-radius: 0px !important;
    }
    /* form wrapper */
    ._form-content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        box-sizing: border-box;
        margin: 0px -5px; /* align with wrapper */
        ._full_width {
            display: inline-block;
            padding: 0px 5px;
            box-sizing: border-box;
            &._button-wrapper {
                margin: 10px 0px;
            }
        }
        /* last input (email/message) */
        div:nth-last-of-type(3){
            flex-grow: 1;
        }
    }
    ._form_element input::placeholder {
         color:white;
    }
}
/* subscribe only */
#_form_3_ {
    color: #FFF !important;
    input[type="text"],
    input[type="date"],
    select,
    textarea {
        border-color: #FFF;
    }
    ._form-content {
        padding-right: 80px; /* for button */
        ._full_width {
            width: 30%;
            &._button-wrapper {
                width: auto;
                position: absolute;
                right: 0px;
                bottom: 0px;
                margin-top: 0px;
            }
        }
    }
    ._submit {
        color: #FFF !important;
        background-color: $one !important;
        border: 1px solid $one !important;
    }
}
/* subscribe interest (store) only */
#_form_5_ {
    color: #FFF !important;
    input[type="text"],
    input[type="date"],
    select,
    textarea {
        border-color: #FFF;
        color: #FFF;
    }
    ._form-content {
        // padding-right: 80px; /* for button */
        ._full_width {
            width: 30%;
            &._button-wrapper {
                width: 100%;
            }
        }
        /* last input (email/message) */
        div:nth-last-of-type(3){
            flex-grow: 0;
        }
    }
    ._submit {
        color: #FFF !important;
        background-color: $one !important;
        border: 1px solid $one !important;
    }
    input[type="text"], #_form_3_ input[type="date"], #_form_3_ textarea, select {
        color: #FFF !important;
    }
}
footer {
    color:#fff;
    .flex {
        align-content: flex-start;
        > * {
            display: inline-flex;
        }
    }
    .half,
    nav,
    .logo-wrap,
    .col {
        width: 50%;
        box-sizing: border-box;
    }
    .col {
        padding: 0px 30px;
    }
    a.logo {
        padding-top: 1em;
    }
    nav {
        flex-grow: 1;
        box-sizing: border-box;
        li a:hover,
        h3 a:hover {
            color: #000;
        }
        h3 {
            margin-top: 0px;
        }
        > div {
            display: inline-block;
            width: 50%;
            flex-grow: 1;
            padding-top: 1em;
            padding-left: 30px;
            box-sizing: border-box;
            border-left: 1px solid #FFF;
        }
    }
}
.footer-top {
    position: relative;
    background-color: $three;
    background-size: cover;
    padding: 10% 0px;
    @include darken_before;
    h3 {
        color: #FFF;
    }
    nav {
        font-size: 16px;
    }
    .button {
        color: #FFF;
        border-color: #FFF;
        &:hover {
            &:before {
                background-blend-mode: normal;
            }
        }
        &:before {
            background-image: none;
            background-color: $one;
        }
    }
}
.footer-bottom {
    background-color: $one;
    .contentwrap {
        padding: 20px 30px;
    }
}

.reveal{
    position: relative;
    transform: translateY(100px);
    opacity: 0;
    transition: 1.5s opacity ease, 2.2s transform ease;
    &.active{
        transform: translateY(0);
        opacity: 1;
    }
}


/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    a {
        color: inherit;
        text-decoration:none;
        &:hover img{
            transform-origin: center;
            transform: translateX(-200%) rotate(-360deg);
        }
    }
    img {
        vertical-align: middle;
        display: inline-block;
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
    }
}


/*============ home ============*/
.t-home {
    .page-graphic {
        padding-bottom: 5%;
        &.fallback {
            min-height: 0px;
        }
        .contentwrap {
            justify-content: center; /* vertical */
            align-items: flex-end; /* right */
        }
    }
}
.home-wrap {
    text-align: left;
    width: 400px;
    max-width: 100%;
    margin-left: auto;
 
    nav.buttons-menu {

        li {
            &:nth-of-type(3n+1) a { /*pink*/
                color: $one;
                border-color: $one;
                &:hover {
                    color: #FFF;
                }
            }
            &:nth-of-type(3n) a { /*khaki*/
                color: $three;
                border-color: $three;
                &:hover {
                    color: #FFF;
                }
            }
            a {
                background-color: #FFF;
            }
        }
    }
}

/** home content area ***/
#content.bg-one {
    nav.buttons-menu {
        float: right;
        /* shift down to line up with heading */
        position:relative;
        top:100px;
        li a {
            color: #FFF;
            border-color: #FFF;
        }
    }
}

/** product feed area **/
@mixin home_product_icon(){
    position: relative;
    border: 10px solid #FFF;
    @include drop_shadow;
    &:before {
        content: "";
        display: block;
        width: 100%;
        padding-top: 80%;
    }
}
.product-feed {
    .product_pg {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        width: 30%;
        padding-right: 40px;
        box-sizing: border-box;
        h2 {
            font-size: max(2em, 2vw);
        }
        h4 {
            margin-top: 0px;
        }
    }
    .scrollwrap {
        overflow: auto;
        &.sidebar {
            flex-wrap: wrap;
            .mask {
                display: inline-block;
                width: 70%;
                padding-left: 40px;
                box-sizing: border-box;
                overflow: hidden;
            }
            .item {
                 width: 50%;
            }
        }
        &.full {
            .item {
                width: 25%;
            }
        }
    }
    /* scroll items */
    .item {
        vertical-align: top;
        padding: 20px;
        box-sizing: border-box;
        margin: 0px;
        .icon {
            @include home_product_icon;
        }
        .content {
            padding: 20px;
        }
        .title {
            font-size: 2em;
        }
    }
}

.featured-products {
    background-color: $tint;
}
.featured {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 3% 0px;
    width: 100%;
    > div {
        display: inline-block;
        width: 48%;
        box-sizing: border-box;
        &.content {
            display: inline-flex;
            flex-direction: column;
            padding-left: 5%;
            flex-grow: 1; /* match height */
            .title {
                font-size: 2em;
                margin-top: 20px;
                max-width: 400px;
            }
            .subtitle {
                margin-top: 5px;
            }
        }
    }
    .icon {
        @include home_product_icon;
        background-color: $lightgrey;
        background-size: cover;
    }
}

/*  banner generic*/
.banners {
    position: relative;
    margin: 60px;
    margin-bottom: 30px;
}
.banner-stage {
    position: relative;
    min-height: 80vh;
}
.banner {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display: block;
    background-color: $txt;
    background-position:center;
    background-size: cover;
    text-decoration: none;
    opacity: 0;
    z-index: 0;
    transition: opacity 1s ease;
    @include darken_before;
    &.active {
        opacity: 1;
        z-index: 1;
    }
}
.banner-content {
    position: relative;
    color: #FFF;
    padding: 5%;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    > span {
        display: block;
    }
}
.banner-navigation {
    display: block;
    .banner-navigation-body {
        position: relative;
        text-align: center;
        display: block;
        padding-top: 30px;
    }
    .banner-nav-item {
        @include dot;
        &:hover {
            background-color: $four;
            cursor: pointer;
        }
        > span {
            display: none;
        }
    }
}
/** slideshow **/
.banners-home_slideshow, .banners-the-store {
    .banner {
        display: flex;
        flex-direction: column;
        justify-content: flex-end; /* bottom */
        align-items: flex-start; /* left*/
    }
    .banner-content {
        width: 400px;
        max-width: 100%;
        padding-right: 0px;
    }
    .banner-name, .banner-title {
        font-weight: bold;
        margin-bottom: 1em;
    }
    .banner-name {
        font-size: 2em;
        em,
        i {
            font-family: $fancy;
            font-style: italic;
            font-weight: 700;
        }
    }
    .banner-caption {
        font-size: 0.88em;
        padding-top: 1em;
    }
}

/** member + testimonials section **/
.people {
    .flex {
        > div {
            display: inline-block;
            display: inline-flex; /* to make contents fill height */
            width: 50%;
            flex-grow: 1;
            box-sizing: border-box;
            &:first-of-type {
                background-color: $tint;
            }
        }
        .wrap {
            display: flex; /* content fill height */
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;
            padding: 5% 15%;
            width: 100%;
            box-sizing: border-box;
            > a {
                text-decoration: none;
                display: flex;
                justify-content: center;;
                justify-content: space-evenly;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                box-sizing:  border-box;
            }
            .txt {
                width: 50%;
                padding-right: 8%;
                box-sizing: border-box;
            }
        }
    }
    .circles {
        order: 1; /* push right */
        width: 300px;
        height: 300px;
        position: relative;
    }
    .circle {
        width: 300px;
        opacity: 0;
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        transition: opacity .5s ease;
        &.active {
            opacity: 1;
        }
    }
}
.testimonial {
    width: 100%;
    box-sizing: border-box;
    .teaser {
        display: block;
        font-size: 2em;
        color: $five;
        font-family: $fancy;
        padding: 30px 0px;
    }
    h4 {
        margin: 0px;
        &:last-of-type {
            margin-bottom: 30px;
        }
    }
}
.testimonial-feed {
    .wrap {
        justify-content: flex-end;
        justify-content: space-evenly;
        justify-content: space-between; /* top + bottom */
    }
    .scrollwrap {
        flex-direction: column;
    }
    .scroller {
        order: 1; /* push down */
    }
}

/*============ page layout ============*/
.page-graphic {
    position: relative;
    color: #FFF;
    background-color: $txt;
    background-size: cover;
    background-position: center;
    padding: 120px 0px; /* height of the header */
    padding-bottom: 32%;
    padding-bottom: 32vw; /* based on width */
    min-height: 100vh;
    box-sizing: border-box;
    /* allow contentwrap inside to grow */
    display: flex;
    flex-direction: column;
    @include darken_before;
    h2, h4 {
        color: #FFF;
    }
    .contentwrap { /* pages */
        text-align: center;
        width: 1260px; /* plus padding */
        max-width: 100%;
        box-sizing: border-box;
        /* fill height and align to the bottom */
        display: flex;
        flex-direction: column;
        flex-grow: 1; /* fill height */
        justify-content: flex-end; /* bottom */
    }
    &.fallback {
        &:before {
            display: none;
        }
        background-color: $five;
    }
    label {
        color: #FFF;
    }
    option {
        background-color: #fff;
        color:#000;
    }
}
.page-title {
    margin-bottom: 0px;
    em,
    i {
        font-family: $fancy;
        font-style: italic;
        font-weight: 700;
    }
}
.page-subtitle {
    margin: 0px;
    margin-top: 30px;
}
.page-wrap {
    .contentwrap {
        padding-top: 5%;
        padding-bottom: 5%;
        background-color: #FFF;
        margin-top: -32%;
        margin-top: -32vw; /* based on padding in page-graphic */
        z-index: 1;
        &:before,
        &:after {
            content: "";
            display: block;
            background-color: inherit;
            position: absolute;
            top: 0px;
            height: 100%;
            z-index: 0;
        }
        &:before { /* left */
            right: 100%;
            width: 3%;
        }
        &:after { /* right */
            left: 100%;
            width: 200%;
        }
    }
    section {
        padding: 6% 0px;
    }
}

/*============ search layout ============*/
.search-wrap {
    .result {
        a {
            color: inherit;
            text-decoration: none;
        }
        h3 {
            margin: 0px;
        }
        padding: 20px 0px;
        border-bottom: 1px solid $four;
        &:last-of-type {
            border-bottom: 0px;
        }
    }
}

/*============ Contact ============*/
/** two column - used on shared item **/
.t-cols {
    .col-right,
    .col-left {
        width: 50%;
        box-sizing: border-box;
    }
    .col-right {
        order: 1; /* push right */
    }
    .col-left {
        padding-right: 15%;
        flex-grow: 1;
    }
}
/** contact page different **/
.t-contact {
    .col-right {
        width: 55%;
    }
    .col-left {
        width: 35%;
        padding-right: 5%;
    }
}


/* contact sidebar */
.contact-details {
    > .name {
        margin-top: 0px;
    }
    .site-contact {
        font-weight: bold;
        color: $four;
    }
}
.opening-hours {
    display: block;
}
.opening-days {
    .opening-seperator {
        position: relative;
        width: auto;
        display: inline-block;
        &:before {
            content:"to";
            position: relative;
            display: inline-block;
            padding: 0px 5px;
            background-color: #FFF;
            margin-right: -7px;
            z-index: 1;
        }
    }
    .opening-to {
        &:after {
            content:", ";
        }
    }
}
.site-contact{
    .address-wrapper{
        display: grid;
        grid-template-columns: auto 1.5fr;
        align-items: center;
        grid-gap:1rem;
        img{
            margin: 0;
        }
    }
  
}
.contact-details{
    .stores-wrapper{
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
        @media screen and (max-width: 1200px) {
            flex-wrap: wrap;
        }
        @media screen and (max-width: 760px) {
            justify-content: center;
            .address-wrapper{
                grid-template-columns: 1fr;
                justify-content: center;
                grid-gap:0;
            }
        }
    }
}
/* map square */
.map-wrap {
    background-color: $lightgrey;
    margin-top:3rem;
}
.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
#map,
#map-click {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#map {
    display: block;
}

/* form embed overrides */
#contact-form {
    padding: 0;
    .page-title{
        margin: 0;
    }
    form {
        padding-top: 30px;
    }

}
/* activecampaign overrides (some in subscribe section) */
#_form_1_ {
    input[type="text"],
    input[type="date"],
    select,
    textarea {
        border-color: $four;
    }
    ._form-content {
        ._full_width {
            width: 50%;
            flex-grow: 1; /* uneven will grow */
        }
        /* clearfix, button and message */
        div:nth-last-child(-n+3){
            width: 100%;
        }
        input[data-name="message"]{
            min-height: 80px;
        }
    }
}


/* promoted bottom feed */
.promo-feed {
    border-top: 1px solid $four;
    padding-top: 2rem;
}

/*============ content lists (shared index) ============*/
/* generic */
.flex.list {
    justify-content: flex-start; /* left */
    /* align with contentwrap */
    width: 103%; /* increase by margin amounts */
    margin-left: -1.5%;
    margin-right: -1.5%;
}
div.item {
    display: inline-flex; /* so inside link can match height */
    width: 32.3%;
    padding: 1%;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0px 0.5%;
    .title {
        font-size: 1.33em;
        margin-top: 5px;
        margin-bottom: 20px;
    }
    .icon {
        @include responsive_square;
        background-color: $lightgrey;
        background-size: cover;
        > div { /* staff graphic or join button */
            position: absolute;
            top: 50%;
            margin-top: -25%;
            z-index: 1;
        }
    }
    > a {
        display: flex;
        flex-direction: column;
        flex-grow: 1; /* match height */
        color: inherit;
        text-decoration: none;
    }
    .content {
        display: flex; /* fill height */
        flex-direction: column;
        flex-grow: 1;
        align-items: flex-start;
        padding: 20px 0px;
        > h4 {
            margin-top: 0px;
        }
        .buttons {
            margin-top: auto; /* push down */
            > p {
                margin-bottom: 0px;
            }
        }
    }
}
.icon {
    &.fallback {
        background-image: url('../assets/fallback.jpg')
    }
}

/* pages */
.pages {
    padding-top: 30px;
    text-align: center;
    .button {
        font-weight: bold;
        padding: 0px 5px;
        height: 40px;
        line-height: 40px;
        min-width: 40px;
        margin: 5px;
        cursor: pointer;
    }
}

/*============ members (our community) ============*/
div.staff {
    .title:before {
        content: "Member Profile: ";
    }
    .subtitle {
        display: none;
    }
    .join {
         .title:before {
            display: none;
         }
    }
}

/* logo on hover */
.staff {
    .icon {
        &:before,
        .graphic {
            opacity: 0;
            transition: opacity $link_trans;
        }
        &:before { /* responsive square */
            background-color: $darken_bg;
        }
        .graphic {
            @include responsive_square;
            position: absolute;
            width: 60%;
            margin-top: -30%;
            left: 50%;
            margin-left: -30%;
            background-color: #FFF;
            background-size: 60%;
            background-repeat: no-repeat;
            border-radius: 50%;
        }
    }
    .item a:hover {
        .icon {
            &:before,
            .graphic {
                opacity: 1;
            }
        }
    }
}

/* promo item */
div.join {
    .icon {
        background-color: $one;
        &:before {
            background-color: transparent;
        }
        > div {
            display: block;
            text-align: center;
            width: 100%;
            margin-top: - 15px;
        }
        span.button {
            color: #FFF;
            border-color: #FFF;
        }
    }
    a:hover {
        .icon span.button {
            color: #FFF;
            &:before {
                opacity: 1;
                background-blend-mode: multiply;
            }
        }
    }
}

/*============ news ============*/
.t-news {
    /* logo overlay style */
    div.icon {
        &:before {
            /* slight darken */
            background-color: rgba(0,0,0, 0.1);
            transition: background 0.5s ease;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            display: block;
            background-image: url("../assets/logo-icon.svg");
            background-size: 40%;
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
        }
    }
    div.icon:hover {
        &:before {
            /* slight darken */
            background-color: rgba(0,0,0, 0.5);
        }
    }
}

/*============ products (things to hire) ============*/
.t-products {
    .page-graphic {
        padding-bottom: 16%;
        padding-bottom: 16vw; /* less overlap */
    }
    .page-wrap .contentwrap {
        margin-top: -16%;
        margin-top: -16vw;
    }
    div.item { /* below style = default */
        text-align: left;
        padding: 2%;
        width: 46%;
        margin: 2%;
        @include drop_shadow;
        .content {
            .full-content {
                h4:first-of-type,
                p:first-of-type {
                    margin-top: 5px;
                }
            }
        }
        .title {
            margin-bottom: 0px;
        }
        .subtitle {
            display: none;
        }
    }
    .above {
        .list.flex {
            width: 102%;
            margin-left: -1%;
            margin-right: -1%;
        }
        .item { /* blue = default */
            position: relative;
            width: 31%;
            padding: 0px;
            margin: 1%;
            h4 {
                color: $four;
                font-size: 16px;
            }
            &:before,
            &:after {
                content: "";
                position: relative;
                display: block;
                background-color: $four;
            }
            &:before{
                height: 20px;
            }
            &:after {
                height: 40px;
                background-image: url('../assets/fallback.jpg');
                background-size: 300%;
                background-position: center bottom;
            }
            &:nth-of-type(3n+1) {
                h4 {
                    color: $one;
                }
                &:before,
                &:after,
                .savings-label {
                    background-color: $one;
                }
                &:after {
                    background-position: left bottom;
                }
            }
            /** every 2nd = blue (default) **/
            &:nth-of-type(3n) {
                h4 {
                    color: $three;
                }
                &:before,
                &:after,
                .savings-label {
                    background-color: $three;
                }
                &:after {
                    background-position: right bottom;
                }
            }
        }
        .content {
            padding: 30px;
        }
    }
}

section.items {
    text-align: center;
    padding-top: 0px;
    .list.flex {
        justify-content: center;
    }
}


/*============ testimonials ============*/
/* item content displayed in list page */
.t-list {
    .testimonial {
        padding: 20px 0px;
    }
}

/*============ shared item ============*/
/* top row */
.col-left {
    .published-date,
    .position {
        color: $five; /*navy*/
    }
}
.item-title {
    margin-top: 0px;
}
/* this icon half screen width */
.t-item {
    .col-right.img {
        position: relative;
        > div {
            position: relative; /* to col2 */
            top: 0px;
            left: 0px;
            width: 150%;
            width: 50vw;
            z-index: 1;
        }
    }
}

/* bottom row */
.flex.last {
    padding: 5% 0px;
}
.share {
    h4 {
        color: $five;
        display: inline-block;
        margin: 5px;
        margin-left: 0px;
    }
    .site-link.email {
        /*height: 18px;*/ /* This was causing the email image to sit lower than other images */
        border-radius: 0px;
    }
}
.item-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-items: flex-start;
    align-items: center;
    margin: 0px -5px;
    > * {
        margin: 5px;
    }
}

/** member (staff) **/
.t-staff {
    .item-title {
        &:before {
            content:"member profile";
            font-family: $fancy;
            font-style: italic;
            font-weight: 700;
        }
        span {
            display: block;
        }
    }
}

.member-contact {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: space-evenly;
    justify-content: space-between;
    align-items: center;
    background-color: $tint;
    padding: 30px 8%;
    max-width: 100%;
    box-sizing: border-box;
    > div {
        display: inline-block;
        &.details {
            flex-grow: 1;
            max-width: 70%;
        }
        &.graphic {
            order: 1; /* push right */
            background-size: 55%;
            background-repeat: no-repeat;
            width: 200px;
            background-color: #FFF;
            max-width: 25%;
        }
        .button {
            background-color: #FFF;
        }
    }
}

.page-icon{
    margin-bottom:2rem;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 1500px){
/* page */
    .page-graphic {
        padding-bottom: 20%;
        min-height: 70vh;
    }
    .page-wrap {
        padding-left: 30px;
        .contentwrap {
            margin-top: -20%;
            &:before {
                display: none;
            }
        }
    }
/* footer align with page content */
    .footer-top {
        padding-left: 30px;
    }
}

@media only screen and (max-width: 1200px){
/** home **/
    .scroll-nav {
        text-align: center;
        padding: 20px 0px;
        padding-bottom: 0px;
        margin-left: 0px;
    }

    /* product feed */
    .product-feed {
        .scrollwrap {
            &.sidebar {
                flex-direction: column;
                .product_pg,
                .mask {
                    display: block;
                    width: 100%;
                    padding: 0px;
                }
                .product_pg {
                    text-align: center;
                }
            }
        }
    }
    /* member + testimonials */
    .people {
        .flex,
        .flex > div {
            display: block;
            width: 100%;
        }
        .flex .wrap {
            padding: 30px;
            > a {
                justify-content: center;
                justify-content: space-evenly;
            }
            .txt {
                padding-right: 30px;
            }
        }
    }

    .testimonial-feed {
        .scroller {
            order: 0;
        }
        .testimonial {
            text-align: center;
            padding: 0px 10px;
        }
    }
    /** banner **/
    .banner-stage {
        padding-top: 60%;
        min-height: 500px;
        box-sizing: border-box;
    }
/** content items **/
    .t-cols .col-left {
        padding-right: 30px;
    }
    .flex.last {
        padding: 30px 0px;
    }
    /* member (staff) **/
    .member-contact {
        padding: 20px 30px;
    }
    /** product list **/
    .t-products {
        .above {
            .item {
                width: 46%;
                margin: 2%;
            }
        }
    }
/** footer **/
    footer {
        .half {
            width: 40%;
            flex-direction: column;
            > div {
                width: 100%;
            }
        }
        .logo-wrap {
            padding-right: 30px;
        }
        .col {
            padding-left: 0px;
        }
        a.logo {
            padding: 20px 0px;
        }
    }
    .footer-top {
        padding: 30px 0px;
        padding-left: 30px; /* align with page content */
    }
}
@media only screen and (max-width: 1100px){
/** home **/
    /* pink content section */
    #content.bg-one {
        nav.buttons-menu {
            top:50px;
        }
    }
}
@media only screen and (max-width: 960px) {
/** main menu **/
    .slide-menu {
        width: 240px;
        right: -240px;
        .wrap {
            padding: 0px;
        }
        nav {
            padding-top: 40px;
            padding-bottom: 0px;
            li a,
            h3 a {
                padding: 10px;
                padding-left: 18px;
            }
            h3 {
                margin: 0px;
            }
        }
        .discover h3 {
            min-height: 30px;
            line-height: 30px;
            padding-bottom: 10px;
            padding-left: 18px;
        }
        .site-contact {
            padding-left: 18px;
            img{
                width: 50px;
            }
            p:first-of-type {
                margin-top: 5px;
            }
            .item a {
                display: block;
                line-height: 30px;
                min-height: 30px;
            }
        }
        .site-links {
            padding-top: 0px;
        }
        form.search {
            padding-left: 1em;
            padding-right: 5px;
        }
    }
/** page **/
    .page-graphic {
        min-height: 150px;
        padding-bottom: 0px;
    }
    .page-wrap {
        padding-left: 0px;
        .contentwrap { /* no overlap */
            margin-top: 0px;
        }
    }
/** home **/
    /* graphic */
    .home-wrap {
        h2 {
            margin: 0px;
            &:first-of-type {
                margin-top: 1em;
            }
            &:last-of-type {
                margin-bottom: 1em;
            }
        }
        text-align: center;
        width: 100%;
        nav.buttons-menu > ul {
            justify-content: center;
        }
    }
    /* scrollers */
    .scroll-nav {
        text-align: center;
        padding: 0px;
        padding-top: 20px;
        margin-left: 0px;
    }
    /* feature stack */
    .featured {
        flex-direction: column;
        align-items: center;
        padding: 10px 0px;
        width: 100%;
        box-sizing: border-box;
        > div {
            &.icon {
                width: 500px;
                max-width: 100%;
            }
            &.content {
                display: block;
                width: 100%;
                text-align: center;
                padding: 20px 0px;
            }
        }
    }
    /** banner **/
    .banners {
        margin: 30px;
        margin-bottom: 10px;
    }
    .banner-navigation {
        .banner-navigation-body {
            padding-top: 10px;
        }
        .banner-nav-item {
            margin: 5px 10px;
        }
    }
    .banners-home_slideshow, .banners-the-store {
        .banner-content {
            padding-right: 5%;
            width: 100%;
        }
    }
    .testimonial-feed .testimonial {
        h4:last-of-type {
            margin-bottom: 0px;
        }
    }
/** shared items **/
    .t-item {
        .col-right,
        .col-left {
            width: 100%;
            padding: 0px;
        }
        .col-right {
            order: 0;
            &.img {
                padding-bottom: 30px;
                > div {
                    width: 100%;
                    > div:first-of-type {
                        max-width: 400px;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
    .flex.last,
    .share {
        padding: 20px 0px;
    }
/** footer **/
    .footer-top {
        padding-left: 0px;
    }
}

@media only screen and (max-width: 760px) {
/** generic **/
    a {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;
    }
    .site-links {
        padding-top: 10px;
    }
/** contact **/
    .t-contact {
        .col-right,
        .col-left {
            width: 100%;
            padding: 0px;
        }
        .col-right {
            order: 0;
        }
    }
    .map-wrap {
        margin: 30px auto;
    }
    #map-click {
        display: block;
    }
    .t-contact .col-left,
    .contact-details {
        text-align: center;
        margin-top: 3rem;
    }
    #contact-form {
        padding-top: 40px;
        h2,
        h4 {
            text-align: center;
        }
    }
/** home **/
    /* product scroller */
    .product-feed {
        .scrollwrap {
            &.sidebar,
            &.full {
                .item {
                    width: 100%;
                }
            }
        }
    }
    /* pink content section */
    #content.bg-one {
        nav.buttons-menu {
            float:none;
            top:0;
        }
    }
    /* people */
    .people .flex .wrap {
        > a {
            display: block;
            text-align: center;
        }
        .circle {
            max-width: 100%;
            margin: 0 auto;
        }
        .txt {
            width: 100%;
        }
    }
/** page **/
    .page-wrap {
        .contentwrap {
            padding-top: 40px;
            padding-bottom: 0px;
        }
        section {
            padding: 30px 0px;
            &.items { /* product index override */
                padding-top: 0px;
            }
        }
    }
    .page-subtitle {
        margin-top: 1em;
    }
/** content lists **/
    div.item {
        width: 49%;
        &:first-of-type {
            margin-top: 0px;
        }
    }
    /** product list **/
    .t-products {
        .flex.list {
            justify-content: center;
            width: 100%;
            margin: 0px;
        }
        div.item {
            width: 500px;
            padding: 5%;
            margin: 20px 0px;
            &:first-of-type {
                margin-top: 0px;
            }
        }
        .above {
            .flex.list {
                justify-content: center;
                width: 100%;
                margin: 0px;
            }
            .item {
                width: 500px;
                padding: 0px;
                margin: 20px 0px;
            }
            .content {
                padding: 5%;
            }
        }
    }
/** footer **/
    /* subscribe */
    #subscribe {
        .subscribe-top {
            padding: 0px;
            h2 {
                font-size: 2em;
            }
        }
    }
    #_form_3_,
    #_form_5_ {
        ._form-content {
            max-width: 400px;
            padding-right: 0px;
            box-sizing: border-box;
            margin: 0 auto;
            ._full_width {
                width: 100%;
                &._button-wrapper {
                    position: static;
                    width: 100%;
                }
            }
        }
    }
    footer {
        .flex {
            display: block;
        }
        .half,
        nav {
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
        .logo-wrap,
        .col {
            text-align: center;
            padding: 0px;
        }
        nav {
            padding-top: 20px;
            > div {
                display: block;
                text-align: center;
                width: 100%;
                padding: 0px;
                padding-top: 10px;
                border-left: 0px;
                &:first-of-type {
                    padding-top: 20px;
                    border-top: 1px solid #FFF;
                }
                h3 {
                    margin-bottom: 5px;
                }
                > ul {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
            li a {
                padding: 10px;
            }
        }
    }
    footer,
    .contact-details {
        .site-contact {
            .row {
                display: inline;
            }
        }
    }
    .footer-top {
        padding: 0px;
        padding-bottom: 20px;
    }
}

@media only screen and (max-width: 550px){
/* page */
    .page-title {
        margin-top: 5px;
    }
/* home */
    nav {
        &.buttons-menu {
            li {
                flex-grow: 1;
                a {
                    width: 100%;
                }
            }
        }
    }
    /* banner */
    .banners-home_slideshow, .banners-the-store {
        .banner-name {
            font-size: 1.5em;
            margin-bottom: 20px;
        }
        .banner-caption {
            padding-top: 0px;
        }
    }
    /* testimonial feed */
    .testimonial .teaser {
        font-size: 1.5em;
    }
/* contact */
    #contact-form form {
        padding-top: 0px;
    }
    #_form_1_ {
        ._form-content {
            margin: 0px;
            ._full_width {
                width: 100%;
                padding: 0px;
                &._button-wrapper {
                    margin-top: 0px;
                }
            }
        }
    }
/* footer */
    #subscribe {
        .subscribe-top {
            padding: 0px;
            .button {
                span {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 500px){
/** content lists **/
    .t-list {
        .page-wrap .contentwrap {
            text-align: center;
        }
    }
    .flex.list {
        justify-content: center;
    }
    div.item {
        text-align: left;
        width: 300px;
        max-width: 100%;
        padding: 0px;
        margin: 20px 0px;
    }
/** content item **/
    .member-contact {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px 10px;
        > div {
            &.details {
                max-width: 100%;
            }
            &.graphic {
                order: 0;
                max-width: 70%;
            }
        }
    }
}
/* ==========================================================================
   Helper classes
   ========================================================================== */

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}
