.t-store{
    .splash-wrap{
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap:2rem;
    }
    .clock{
        font-family: "lust-display", serif;
        font-size: 85px;
        color: #dab245;
        text-shadow: 0px 0px 10px #000;
        display: flex;
        .clock__section {
            margin: 0 0.5rem;
            white-space: nowrap;
        }
        .clock__label {
            font-size: 36px;
        }
    }
    .home-wrap{
    
        .buttons-menu{
            justify-content: center;
            display: flex;
        }
    }
}
@media screen and (max-width: 1300px) {
    .t-store {
        .clock {
            font-size: clamp(7px, 6vw, 85px);
            .clock__label {
                font-size: clamp(7px, 2vw, 36px);
            }
        }
    }
}
@media screen and (max-width: 1100px) {
    .t-store {
        .clock {
            font-size: clamp(7px, 4.5vw, 85px);
            .clock__label {
                font-size: clamp(7px, 1.5vw, 36px);
            }
        }
    }
}
@media screen and (max-width: 960px) {
    .t-store {
        .clock {
            font-size: clamp(7px, 3vw, 85px);
            .clock__label {
                font-size: clamp(7px, 1.5vw, 36px);
            }
        }
    }
}

@media screen and (max-width: 768px){
    .t-store{
        .splash-wrap{
            flex-wrap: wrap;
            .clock{
                order:1;
                font-size: clamp(7px, 6vw, 85px);
            }
            .home-wrap{
                order:0;
            }
        }
    }
}
